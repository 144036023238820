// import { HiOutlineLink } from 'react-icons/hi'
// import { RiGithubFill } from 'react-icons/ri'
import './../styles/List.css'

const Projects = () => {
  return (
    <section className='projects section' id='projects'>
      <h2 className='section-title'>Projects</h2>
      <div className='projects-container bd-grid'>
        <div className='projects-content'>
          <div className='projects-time'>
            <div className='projects-rounder'></div>
            <div className='projects-line'></div>
          </div>
          <div className='projects-data bd-grid'>
            <h3 className='projects-title'>WomenTechies '21</h3>
            <p className='projects-info'>Event Website for DSCVIT Hackathon</p>
            <div className='projects-links'>
              <a href='https://github.com/GDGVIT/WomenTechies2021/' className='projects-link'>GitHub</a>
              &nbsp;|&nbsp;
              <a href='https://womentechies.dscvit.com/' className='projects-link'>Link</a>
            </div>
            <ul className='projects-details'>
              <li>Implemented single-handedly using ReactJS</li>
              <li>Used and edited source-code of external libraries</li>
              <li>Multiple sections based on club's requirements</li>
              <li>Discord widget with dynamic animations</li>
              <li>Easter Eggs to surprise participants</li>
            </ul>
          </div>
        </div>
        <div className='projects-content'>
          <div className='projects-time'>
            <div className='projects-rounder'></div>
            {/* <div className='projects-line'></div> */}
          </div>
          <div className='projects-data bd-grid'>
            <h3 className='projects-title'>Gandalf</h3>
            <p className='projects-info'>Smart Proxy Tool</p>
            <div className='projects-links'>
              <a href='https://github.com/Atom-Heart-Father/Gandalf' className='projects-link'>GitHub</a>
              {/* <a href='' className='projects-link'><HiOutlineLink /></a> */}
            </div>
            <ul className='projects-details'>
              <li>Tool to control traffic on a server based on user-specified categories</li>
              <li>Entire project built in 36-hours</li>
              <li>Won Runner's Up at DevSpace 2021</li>
              <li>Built the website using ReactJS</li>
              <li>Formatted data for ML model</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects
