import React from 'react'
import NavItem from './NavItem'
import './../styles/Header.css'
import { AiOutlineHome, AiOutlineUser, AiOutlineFundProjectionScreen } from 'react-icons/ai'
// import { BiBook, BiReceipt, BiLinkExternal, BiGridAlt } from 'react-icons/bi'
import { BiReceipt, BiGridAlt } from 'react-icons/bi'
import { HiOutlineAcademicCap } from 'react-icons/hi'
// import { BsBriefcase } from 'react-icons/bs'
import { BsLightning } from 'react-icons/bs'
import { RiAwardLine } from 'react-icons/ri'

const Header = () => {

  const navItems: { icon: JSX.Element, content: string, ref: string }[] = [
    {icon: <AiOutlineHome />, content: 'Home', ref: 'home'},
    {icon: <AiOutlineUser />, content: 'Profile', ref: 'profile'},
    {icon: <BsLightning />, content: 'Co-Curricular', ref: 'cocurricular'},
    {icon: <BiReceipt />, content: 'Skills', ref: 'skills'},
    {icon: <HiOutlineAcademicCap />, content: 'Education', ref: 'education'},
    // {icon: <BsBriefcase />, content: 'Experience'},
    {icon: <AiOutlineFundProjectionScreen />, content: 'Projects', ref: 'projects'},
    {icon: <RiAwardLine />, content: 'Achievements', ref: 'achieve'},
  ]

  const showMenu = () =>{
    const nav = document.getElementById('nav-menu')
    if(nav) nav.classList.toggle('show-menu')
  }

  const closeMenu = () =>{
    const nav = document.getElementById('nav-menu')
    if(nav) nav.classList.remove('show-menu')
  }

  return (
    <header>
      <nav className='bd-container'>
        <a href='/' className='nav-logo'>Krish Chatterjie</a>
        <div className='nav-menu' id='nav-menu'>
          <ul className='nav-list'>
            {
              navItems.map((item) => (
                <NavItem key={item.content} 
                  item={item} 
                  onClick={() => {
                    closeMenu()
                    document.getElementById(item.ref)?.scrollIntoView({ behavior: 'smooth' })
                  }}/>
              ))
            }
          </ul>
        </div>
        <div className='nav-toggle' onClick={showMenu}>
          <BiGridAlt />
        </div>
      </nav>
    </header>
  )
}
export default Header
