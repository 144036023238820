import { RiGithubFill, RiLinkedinBoxFill, RiTwitterFill } from 'react-icons/ri'
import './../styles/Social.css'

const Social = () => {
  return (
    <section className='social section' id='social'>
      <h2 className='section-title'>Social</h2>
      <div className='social-container bd-grid'>
        <a href='https://github.com/KrishChatterjie' className='social-link'>
          <RiGithubFill /> GitHub
        </a>
        <a href='https://www.linkedin.com/in/krish-chatterjie-3119661b6/' className='social-link'>
          <RiLinkedinBoxFill /> LinkedIn
        </a>
        <a href='https://twitter.com/KrishChatterjie' className='social-link'>
          <RiTwitterFill /> Twitter
        </a>
      </div>
    </section>
  )
}

export default Social
