import Home from './Home'
import Social from './Social'
import Profile from './Profile'
import Skills from './Skills'
import CoCurricular from './Cocurricular'

const Left = () => {
  return (
    <div className='resume-left'>
      <Home />
      <Social />
      <Profile />
      <Skills />
      <CoCurricular />
    </div>
  )
}

export default Left
