import './../styles/List.css'

const Education = () => {
  return (
    <section className='education section' id='education'>
      <h2 className='section-title'>Education</h2>
      <div className='education-container bd-grid'>
        <div className='education-content'>
          <div className='education-time'>
            <div className='education-rounder'></div>
            <div className='education-line'></div>
          </div>
          <div className='education-data bd-grid'>
            <h3 className='education-title'>Vellore Institute of Technology</h3>
            <p className='education-stream'>Computer Science and Engineering</p>
            <span className='education-details'>2020 - Present | Vellore, India</span>
            <p className='education-marks'>CGPA: 9.84/10</p>
          </div>
        </div>
        <div className='education-content'>
          <div className='education-time'>
            <div className='education-rounder'></div>
            {/* <div className='education-line'></div> */}
          </div>
          <div className='education-data bd-grid'>
            <h3 className='education-title'>St. Xavier's Collegiate School</h3>
            <span className='education-details'>2008 - 2020 | Kolkata, India</span>
            <p className='education-marks'>ICSE X: 95.60%</p>
            <p className='education-marks'>ISC XII: 94.75%</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Education
