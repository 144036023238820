import React from 'react'
import './../styles/NavItem.css'

interface Item {
  icon: JSX.Element
  content: string
}

interface Props {
  item: Item
  onClick: Function
}

const NavItem: React.FC<Props> = ({ item, onClick }) => {
  return (
    <li className='nav-item' onClick={() => onClick()}>
      <div className='nav-link'>
        {item.icon}{item.content}
      </div>
    </li>
  )
}

export default NavItem
