import Left from './Left'
import Right from './Right'

const Resume = () => {
  return (
    <div className='resume bd-container' id='resume'>
      <Left />
      <Right />
    </div>
  )
}

export default Resume