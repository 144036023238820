import { useState } from 'react'
import Profile from './../assets/KC.jpg'
import Resume from './../assets/KrishChatterjieResume.pdf'
import { BiEnvelope } from 'react-icons/bi'
import { FiPhone, FiMoon, FiSun, FiDownload } from 'react-icons/fi'
import { RiMapPinLine } from 'react-icons/ri'
import './../styles/Home.css'
import html2pdf from './../html2pdf.bundle.min.js'

const Home = () => {
  const [dark, setDark] = useState(false)
  const [icon, setIcon] = useState(<FiMoon/>)

  const options = {
    margin: [2, 0, 0, 0],
    filename: 'KrishChatterjieResume.pdf',
    image: {
      type: 'png',
      quality: 1,
    },
    html2canvas: { scale: 3, },
    jsPDF: {
      format: 'a4',
      orientation: 'portrait',
    },
  }
  const generatePdf = () => {
    if (dark) document.body.classList.remove('dark')
    document.body.classList.add('scale')
    html2pdf().set(options).from(document.getElementById('resume')).save()
    setTimeout(() => {
      document.body.classList.remove('scale')
      if (dark) document.body.classList.add('dark')
    }, 5000);
  }

  return (
    <section className='home' id='home'>
      <div className='home-container section bd-grid'>
        <div className='home-data bd-grid'>
          <img src={Profile} alt='Krish Chatterjie' className='home-img' />
          <h1 className='home-title'>CHATTERJIE <b>KRISH</b></h1>
          <h3 className='home-profession'>Web Developer</h3>
          <div className='btn-download'>
            <a download='' href={Resume} className='home-button-movil'>Download</a>
          </div>
        </div>
        <div className='home-address bd-grid'>
          <span className='home-information'>
            <RiMapPinLine /> Calcutta, India
          </span>
          <span className='home-information'>
            <BiEnvelope /> <a href='mailto:chatterjie.krish@gmail.com'>chatterjie.krish@gmail.com</a>
          </span>
          <span className='home-information'>
            <FiPhone /> <a href='tel:+919007199129'>+91-9007199129</a>
          </span>
        </div>
      </div>
      <div 
        className='change-theme'
        onClick={() => {
          setDark(!dark)
          !dark ? setIcon(<FiSun />) : setIcon(<FiMoon />)
          document.body.classList.toggle('dark') 
        }}>
        {icon}
      </div>
      <div className='generate-pdf' onClick={generatePdf}>
        <FiDownload />
      </div>
    </section>
  )
}

export default Home
