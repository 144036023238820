import './../styles/Profile.css'

const Profile = () => {
  return (
    <section className='profile section' id='profile'>
      <h2 className='section-title'>Profile</h2>
      <p className='profile-description'>
        Detail-oriented computer science student with a comprehensive understanding of frontend web development. Won second place at DevSpace 2021 Hackathon. Eager to use programming and development skills for a summer internship.
      </p>
    </section>
  )
}

export default Profile
