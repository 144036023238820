import './../styles/List.css'

const Achievements = () => {
  return (
    <section className='achieve section' id='achieve'>
      <h2 className='section-title'>Achievements</h2>
      <div className='achieve-container bd-grid'>
        <div className='achieve-content'>
          <div className='achieve-time'>
            <div className='achieve-rounder'></div>
            <div className='achieve-line'></div>
          </div>
          <div className='achieve-data bd-grid'>
            <h3 className='achieve-title'>DevSpace 2021</h3>
            <p className='achieve-info'>Won 2nd Place Overall</p>
            <ul className='achieve-details'>
              <li>36-hour Hackathon</li>
              <li>Worked on <a href='https://github.com/Atom-Heart-Father/Gandalf' className='social-link'>Project Gandalf</a></li>
            </ul>
          </div>
        </div>
        <div className='achieve-content'>
          <div className='achieve-time'>
            <div className='achieve-rounder'></div>
            <div className='achieve-line'></div>
          </div>
          <div className='achieve-data bd-grid'>
            <h3 className='achieve-title'>BoscoFest 2019</h3>
            <p className='achieve-info'>Won 1st Place Overall</p>
            <ul className='achieve-details'>
              <li>Battle of the Bands</li>
              <li>Lead Guitarist</li>
              <li>... also placed at quite a few other competitions</li>
            </ul>
          </div>
        </div>
        <div className='achieve-content'>
          <div className='achieve-time'>
            <div className='achieve-rounder'></div>
            {/* <div className='achieve-line'></div> */}
          </div>
          <div className='achieve-data bd-grid'>
            <h3 className='achieve-title'>Maitree 2018</h3>
            <p className='achieve-info'>Won 2nd Place Overall</p>
            <ul className='achieve-details'>
              <li>Stand Up Comedy</li>
              <li>... also placed at quite a few other competitions</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Achievements
