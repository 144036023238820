import './../styles/List.css'

const CoCurricular = () => {
  return (
    <section className='cocurricular section' id='cocurricular'>
      <h2 className='section-title'>Co-Curricular</h2>
      <div className='cocurr-container bd-grid'>
        <div className='cocurr-content'>
          <div className="cocurr-time">
            <span className='cocurr-rounder'></span>
            <span className='cocurr-line'></span>
          </div>
          <div className='cocurr-data bd-grid'>
            <h3 className='cocurr-title'>Developer Student Club VIT</h3>
            <span className='cocurr-desc'>Technical Inner-Core Member</span>
            <span className='cocurr-year'>2020 - Present</span>
          </div>
        </div>
        <div className='cocurr-content'>
          <div className="cocurr-time">
            <span className='cocurr-rounder'></span>
            <span className='cocurr-line'></span>
          </div>
          <div className='cocurr-data bd-grid'>
            <h3 className='cocurr-title'>VIT Music Club</h3>
            <span className='cocurr-desc'>Blues Guitarist</span>
            <span className='cocurr-year'>2020 - Present</span>
          </div>
        </div>
        <div className='cocurr-content'>
          <div className="cocurr-time">
            <span className='cocurr-rounder'></span>
            {/* <span className='cocurr-line'></span> */}
          </div>
          <div className='cocurr-data bd-grid'>
            <h3 className='cocurr-title'>SXCS Comedy</h3>
            <span className='cocurr-desc'>Stand Up Comedian reprensenting St. Xavier's</span>
            <span className='cocurr-year'>2016 - 2019</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoCurricular
