import Header from './components/Header'
import Resume from './components/Resume'

function App() {
  return (
    <>
      <Header />
      <Resume />
    </>
  );
}

export default App;
