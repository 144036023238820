import './../styles/Skills.css'

const Skills = () => {
  
  const languages : string[] = [
    'React',
    'JavaScript',
    'TypeScript',
    'HTML',
    'CSS',
    'Python',
    'Flask',
    'Java',
    'C',
    'C++',
  ]

  return (
    <section className='skills section' id='skills'>
      <h2 className='section-title'>Skills</h2>
      <div className='skills-content'>
        {
          languages.map((language) => (
            <div key={language} className='skills-item'>{language}</div>
          ))
        }
      </div>
    </section>
  )
}

export default Skills
