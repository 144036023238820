import Education from './Education'
import Projects from './Projects'
import Achievements from './Achievements'

const Right = () => {
  return (
    <div className='resume-right'>
      <Education />
      <Projects />
      <Achievements />
    </div>
  )
}

export default Right
